<template>
  <div v-if="orderId">
    <router-link
      v-if="orderRoute"
      :to="orderRoute"
      target="_blank"
    >
      <span>{{orderId}}</span>
    </router-link>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  data: () => ({

  }),
  computed: {
    orderId() {
      return this.row.order_id
    },
    orderRoute() {
      if(!this.orderId) return null
      return {
        name: 'order-update',
        params: {
          target: this.orderId
        }
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
